import { Box, Container } from '@mui/material'
import Navbar from '../components/Navbar'
import Footer from '../shared/Footer'
import AboutContent from '../components/AboutPage/AboutContent'
import { PrivacyPolicyCard } from './privacyPolicyCard'

export const PrivacyPolicyDisplay = () => (
	<Box className="repeating-pattern">
		<Navbar />

		<Container sx={{ padding: '20px' }}>
			<PrivacyPolicyCard />
		</Container>

		<Footer />
	</Box>
)
