import { Box, Card, Typography } from '@mui/material'
import { useTranslation } from '../translation/useTranslations'

export const PrivacyPolicyCard = () => {
	const { translations } = useTranslation()
	const { privacyPolicy } = translations

	return (
		<>
			<Card
				sx={{
					height: '100%',
					borderRadius: '8px',
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center'
				}}>
				<Box sx={{ p: 2 }}>
					<Typography variant="h3" component="h2" gutterBottom>
						{privacyPolicy.privacy_policy_title}
					</Typography>
					<Typography variant="body1" gutterBottom>
						{privacyPolicy.privacy_policy_basic_info}
					</Typography>
					<Typography variant="body1" gutterBottom>
						{privacyPolicy.privacy_policy_rights}
					</Typography>
					<Typography variant="body1" gutterBottom>
						{privacyPolicy.privacy_policy_duration}
					</Typography>
					<Typography variant="body1" gutterBottom>
						{privacyPolicy.privacy_policy_legitimacy}
					</Typography>
					<Typography variant="body1" gutterBottom>
						{privacyPolicy.privacy_policy_recipients}
					</Typography>

					<Typography variant="h4" gutterBottom>
						CargoShare Platform
					</Typography>
					<Typography variant="body1" gutterBottom>
						{privacyPolicy.privacy_policy_platform}
					</Typography>
					<Typography variant="body1" gutterBottom>
						{privacyPolicy.privacy_policy_platform_2}
					</Typography>
					<Typography variant="body1" gutterBottom>
						{privacyPolicy.privacy_policy_platform_purpose}
					</Typography>

					<Typography variant="h4" gutterBottom>
						CargoShare for Drivers
					</Typography>
					<Typography variant="body1" gutterBottom>
						{privacyPolicy.privacy_policy_drivers}
					</Typography>
					<Typography variant="body1" gutterBottom>
						{privacyPolicy.privacy_policy_drivers_2}
					</Typography>
					<Typography variant="body1" gutterBottom>
						{privacyPolicy.privacy_policy_drivers_collection}
					</Typography>
					<Typography variant="body1" gutterBottom>
						{privacyPolicy.privacy_policy_drivers_collection_2}
					</Typography>
					<Typography variant="body1" gutterBottom>
						{privacyPolicy.privacy_policy_drivers_collection_3}
					</Typography>
				</Box>
			</Card>
		</>
	)
}
