import React from 'react'
import { Typography, AppBar, Toolbar, IconButton, Box } from '@mui/material'
import { Menu } from '@mui/icons-material'
import { webConfig } from '../../config'
import LanguageSelector from '../../translation/LanguageSelector'
import { NavButton } from './navButton'

export const NavAppBar: React.FC<{ isSmallScreen: boolean; setDrawerOpen: Function; navbarTranslation: any }> = ({
	isSmallScreen,
	setDrawerOpen,
	navbarTranslation
}) => {
	return (
		<AppBar position="fixed" sx={{ height: '70px', boxShadow: 'revert', transition: 'all 0.5s' }}>
			<Toolbar>
				{isSmallScreen && (
					<IconButton edge="start" color="inherit" aria-label="menu" onClick={() => setDrawerOpen(true)}>
						<Menu />
					</IconButton>
				)}
				<Typography variant="h6" sx={{ flexGrow: 1, color: 'white', textAlign: 'left' }}>
					{webConfig.name}
				</Typography>
				{!isSmallScreen && (
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							flexGrow: 1,
							justifyContent: 'flex-end',
							pr: '10px'
						}}>
						<NavButton gaEvent={{ event: 'home_clicked' }} label={navbarTranslation.home} to="/" />
						<NavButton gaEvent={{ event: 'about_clicked' }} label={navbarTranslation.about} to="/about" />
						<NavButton
							gaEvent={{ event: 'privacy_policy_clicked' }}
							label={navbarTranslation.privacy_policy}
							to="/privacy-policy"
						/>
						<NavButton gaEvent={{ event: 'team_clicked' }} label={navbarTranslation.team} to="/team" />
						<NavButton gaEvent={{ event: 'services_clicked' }} label={navbarTranslation.services} to="/services" />
						<NavButton gaEvent={{ event: 'pricing_clicked' }} label={navbarTranslation.pricing} to="/pricing" />
						<NavButton gaEvent={{ event: 'contact_clicked' }} label={navbarTranslation.contact} to="/contact" />
						<LanguageSelector
							translationStyle={isSmallScreen ? { color: 'black' } : { color: 'white', textTransform: 'uppercase' }}
						/>
					</Box>
				)}
			</Toolbar>
		</AppBar>
	)
}
