import './App.css'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { createBrowserHistory } from 'history' // Import createBrowserHistory
import Home from './components/Home'
import Contact from './components/ContactPage/Contact'
import About from './components/AboutPage/About'
import Services from './components/Services'
import Pricing from './components/Pricing'
import Team from './components/Team'
import NotFound from './components/NotFound'
import { TrackingScripts } from './tracking'
import Confirm from './components/Confirm'
import { PrivacyPolicyDisplay } from './privacyPolicy'

const history = createBrowserHistory()

function App() {
	return (
		<>
			<TrackingScripts />
			<BrowserRouter history={history}>
				<Routes>
					<Route path="/" exact element={<Home />} />
					<Route path="/about" element={<About />} />
					<Route path="/services" element={<Services />} />
					<Route path="/team" element={<Team />} />
					<Route path="/privacy-policy" element={<PrivacyPolicyDisplay />} />
					<Route path="/pricing" element={<Pricing />} />
					<Route path="/contact" element={<Contact />} />
					<Route path="/confirm" element={<Confirm />} />
					<Route path="*" element={<NotFound />} />
				</Routes>
			</BrowserRouter>
		</>
	)
}

export default App
